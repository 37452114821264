@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .reader-container {
    @apply min-h-screen flex flex-col font-sans;
  }

  .reader-main {
    @apply flex-grow flex flex-col md:flex-row p-6 overflow-hidden;
  }

  .reader-content {
    @apply flex-grow flex flex-col items-center justify-start overflow-y-auto transition-all duration-300 ml-0 md:ml-8 mt-6 md:mt-0;
  }
}